import React from "react"

const Container = ({ children, noPadding,id, className }) => {
  return (
    <div
      className={`mx-auto max-w-screen-xl px-4 ${noPadding ? "" : "my-8 md:my-16"} ${className ? className : ""}`}
      id={id ? id : ""}
    >
      <div>{children}</div>
    </div>
  )
}

export default Container
