import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./partials/navigation/HeaderMain"
import Footer from "./partials/navigation/FooterMain"
import FooterMin from "./partials/navigation/FooterMin"
import Favicons from "./meta/Favicons"
import AppleWebAppMeta from "./meta/AppleWebAppMeta"
import Facebook from "./meta/Facebook"
import Twitter from "./meta/Twitter"
import FloatingActionButton from "./partials/FloatingActionButton"
import "../modules/klaroStyles.css"
import { config } from "../modules/klaroConfig"
let Klaro
if (typeof window !== `undefined`) {
  Klaro = require("klaro/dist/klaro-no-css")
}

const Default = ({
  children,
  location,
  canonical,
  title,
  description,
  imageFacebook,
  imageFacebookWidth,
  imageFacebookHeight,
  imageTwitter,
  disableHeader,
  disableFooter,
  smallFooter,
}) => {
  useEffect(() => {
    if (
      typeof window !== `undefined` &&
      process.env.NODE_ENV !== "development"
    ) {
      window.klaro = Klaro
      window.klaroConfig = config
      Klaro.setup(config)
    }
  })

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            lang
            author {
              name
              summary
            }
          }
        }
      }
    `
  )

  return (
    <>
      {/* Main */}
      <Helmet>
        <html lang="de" dir="ltr" />
        <title>{title}</title>
        {description && <meta name="description" content={description} />}
        <meta name="robots" content="index,follow" />
        <link
          rel="canonical"
          href={`${canonical
            ? canonical
            : data.site.siteMetadata.siteUrl + location.pathname
            }`}
        />

        <meta
          name="author"
          content={`${data.site.siteMetadata.author.name} - ${data.site.siteMetadata.author.summary}`}
        />
      </Helmet>

      {/* Favicon */}
      <Favicons />

      {/* AppleWebAppMeta */}
      <AppleWebAppMeta />

      {/* Facebook */}
      <Facebook
        url={data.site.siteMetadata.siteUrl + location.pathname}
        title={title}
        description={description ? description : data.site.siteMetadata.description}
        siteName={data.site.siteMetadata.title}
        locale="de_DE"
        imageFacebook={imageFacebook && imageFacebook}
        imageFacebookWidth={imageFacebookWidth && imageFacebookWidth}
        imageFacebookHeight={imageFacebookHeight && imageFacebookHeight}
      />

      {/* Twitter */}
      <Twitter
        url={data.site.siteMetadata.siteUrl + location.pathname}
        title={title}
        imageTwitter={imageTwitter && imageTwitter}
        description={description ? description : data.site.siteMetadata.description}
      />

      {/* Scripts */}
      <Helmet>
        {/* Google Analytics */}
        <script
          type="text/plain"
          data-type="application/javascript"
          data-name="googleAnalytics"
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-LQH1XJFJDH"
        ></script>
        <script
          type="text/plain"
          data-type="application/javascript"
          data-name="googleAnalytics"
          async
        >
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-LQH1XJFJDH'), { 'anonymize_ip': true };
          `}
        </script>

      </Helmet>
      {disableHeader || smallFooter ? <></> : <Header />}
      {children}
      {disableFooter || smallFooter ? <></> : <Footer />}
      {smallFooter && <FooterMin />}
      <FloatingActionButton />
    </>
  )
}

export default Default
