import React from "react"
import { Transition } from '@headlessui/react'
import { Link } from "gatsby"

class DesktopDropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOn: false,
    }

    this.toggleMenu = this.toggleMenu.bind(this)
    this.openMenu = this.openMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
  }

  toggleMenu() {
    this.setState({
      isOn: !this.state.isOn,
    })
  }

  openMenu() {
    this.setState({
      isOn: true,
    })
  }

  closeMenu() {
    this.setState({
      isOn: false,
    })
  }

  render() {
    const dropdownLinks = this.props.mainLinks.map((link, i) => {
      return (
        <Link
          key={i}
          to={link.link}
          className="flex items-start p-3 -m-3 space-x-4 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100"
          onClick={this.closeMenu}
        >
          {link.linkSvg}

          <div className="space-y-1 text-left">
            <p className="text-base font-medium leading-6 text-gray-900">
              {link.linkName}
            </p>
            <p className="text-sm leading-5 text-gray-500">{link.linkDesc}</p>
          </div>
        </Link>
      )
    })

    return (
      <div
        className="relative outline-none focus:outline-none active:outline-none"
        role="button"
        tabIndex={0}
        onKeyDown={this.toggleMenu}
        onMouseEnter={this.openMenu}
        onMouseLeave={this.closeMenu}
        onClick={this.toggleMenu}
      >
        <div
          className={`${this.state.isOn ? "text-gray-500" : "text-gray-700"
            }  group inline-flex items-center space-x-2 text-base leading-6 font-medium hover:text-gray-600 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150`}
        >
          <span>
            <Link to={this.props.mainLink}>{this.props.linkTitle}</Link>
          </span>
          <svg
            className={`${this.state.isOn ? "text-gray-500" : "text-gray-700"
              } h-5 w-5 group-hover:text-gray-600 group-focus:text-gray-500 transition ease-in-out duration-150`}
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <Transition
          show={this.state.isOn}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
          unmount={false}
        >
          <div className="absolute z-20 pt-3 -ml-4">
            <div className="z-20 w-screen max-w-md px-2 transform bg-white  sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
              <div className="rounded-lg shadow-lg">
                <div className="overflow-hidden rounded-lg shadow-xs">
                  <div className="relative z-20 grid gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8">
                    {dropdownLinks}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    )
  }
}

export default DesktopDropdown
