import React from "react"
import Helmet from "react-helmet"

const Twitter = ({
  twitterSite,
  twitterCreator,
  url,
  title,
  imageTwitter,
  description,
}) => {
  return (
    <Helmet>
      <meta name="twitter:card" content="summary" />
      {twitterSite && <meta name="twitter:site" content={twitterSite} />}
      {twitterCreator && (
        <meta name="twitter:creator" content={twitterCreator} />
      )}
      {url && <meta name="twitter:url" content={url} />}
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {imageTwitter && <meta name="twitter:image" content={imageTwitter} />}
    </Helmet>
  )
}

export default Twitter
