import React from "react"
import { Transition } from '@headlessui/react'
import Input from "./../../forms/Input"
import Container from "../../containers/Container"

class NewsletterSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOn: false,
    }

    this.toggleMenu = this.toggleMenu.bind(this)
    this.openMenu = this.openMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
  }

  toggleMenu() {
    this.setState({
      isOn: !this.state.isOn,
    })
  }

  openMenu() {
    this.setState({
      isOn: true,
      scriptLoaded: true,
    })
  }

  closeMenu() {
    this.setState({
      isOn: false,
    })
  }

  render() {
    return (
      <React.Fragment>
        <Container>
        <div className="px-6 py-6 bg-indigo-500 rounded-lg md:py-12 md:px-12 lg:py-16 lg:px-16 lg:flex lg:items-center">
            <div className="lg:w-0 lg:flex-1">
              <h2 className="text-2xl font-extrabold leading-8 tracking-tight text-white sm:text-3xl sm:leading-9">
                Das Thema Finanzen endlich in den Griff bekommen!
              </h2>
              <p
                className="max-w-3xl mt-3 text-lg leading-6 text-white"
                id="newsletter-headline"
              >
                Melde Dich zum Newsletter an und wir zeigen Dir wie Du dieses
                Ziel endlich erreichen kannst.
              </p>
            </div>
            <div className="w-full mt-8 lg:max-w-md lg:mt-0 lg:ml-8">
              <div className="sm:flex">
                <button
                  className="w-full px-6 py-3 text-base font-medium leading-6 text-center transition duration-150 ease-in-out bg-white border border-transparent rounded-md hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-indigo active:bg-blue-500 "
                  onClick={this.openMenu}
                >
                  Zur Newsletter-Anmeldung
                </button>
              </div>
            </div>
          </div>
        </Container>

        <Transition
          show={this.state.isOn}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-start justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 transition-opacity">
                <div
                  className="absolute inset-0 bg-gray-500 opacity-75"
                  role="button"
                  aria-label="Close Modal"
                  onClick={this.closeMenu}
                  onKeyDown={this.closeMenu}
                  tabIndex={0}
                ></div>
              </div>
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" />

              <Transition
                show={this.state.isOn}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div
                  className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="modal-headline"
                >
                  <div>
                    <h2 className="mb-3 text-2xl font-extrabold leading-9 tracking-tight text-indigo-600">
                      Newsletter-Anmeldung
                    </h2>

                    <form
                      action="https://formbackend.dierda.de/sip_registration.php"
                      method="POST"
                    >
                      <div className="mb-4">
                        <Input
                          type="text"
                          label="Vorname"
                          id="name"
                          name="Name"
                          placeholder="Dein Vorname"
                          autoComplete="email"
                        />
                      </div>
                      <div className="mb-4">
                        <Input
                          type="email"
                          label="E-Mail"
                          id="email"
                          name="E-Mail"
                          placeholder="Deine E-Mailadresse"
                          autoComplete="email"
                          required
                        />
                      </div>
                      <p className="mt-3 mb-6 text-sm leading-5">
                        Wir respektieren Dein Vertrauen und behandeln Deine
                        Daten mit Respekt. Mit dem Absenden dieses Formulars
                        akzeptierst Du unsere{" "}
                        <a
                          href="/datenschutz/"
                          className="font-medium underline"
                          target="_blank"
                        >
                          Datenschutzerklärung
                        </a>
                        .
                      </p>
                      <button
                        className="inline-flex items-center justify-center inline-block w-full px-3 py-3 text-lg text-center text-white transition duration-300 ease-in-out border border-transparent rounded shadow btn bgh-gradient"
                        type="submit"
                      >
                        <svg
                          className="-ml-0.5 mr-2 h-4 w-4"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                          <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                        </svg>
                        Jetzt anmelden
                      </button>
                    </form>
                  </div>
                  <div className="mt-2">
                    <span className="flex w-full rounded-md shadow-sm">
                      <button
                        type="button"
                        className="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md shadow-sm hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-100 sm:text-sm sm:leading-5"
                        onClick={this.toggleMenu}
                      >
                        Schließen
                      </button>
                    </span>
                  </div>
                </div>
              </Transition>
            </div>
          </div>
        </Transition>
      </React.Fragment>
    )
  }
}

export default NewsletterSection
