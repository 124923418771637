import React from "react"

export default function FloatingActionButton() {
  return (
    <a
      className={`fixed right-0 bottom-0 mb-5 mr-5 bg-primary hover:bg-indigo-900 transition-colors duration-200 shadow cursor-pointer z-10 flex flex-no-wrap flex-row p-3 rounded-full border-0 text-white`}
      href="tel:+4915164406444"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="#fff"
      >
        <path d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z" />
      </svg>
    </a>
  )
}
