import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import FooterFeatureSection from "./components/FooterFeatureSection"
import NewsletterSection from "../NewsletterSection"

const FooterMain = () => {
  const year = new Date().getFullYear()

  const data = useStaticQuery(graphql`
    query PlacesAdress {
      allGooglePlaces {
        nodes {
          result {
            address_components {
              long_name
              short_name
              types
            }
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <NewsletterSection />
      <FooterFeatureSection className="max-w-screen-xl px-3 mx-auto my-8" />
      <footer className="bg-white">
        <div className="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:py-16 lg:px-8">
          <div className="grid gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5">
            <div>
              <h4 className="text-sm font-semibold leading-5 tracking-wider text-gray-500 uppercase">
                Versicherungen
              </h4>
              <nav className="mt-4">
                <Link
                  to="/versicherungen/berufsunfaehigkeitsversicherung/"
                  className="block text-base leading-6 text-gray-700 hover:text-gray-900"
                >
                  Berufsunfähigkeits&shy;versicherung
                </Link>
                <Link
                  to="/versicherungen/altersvorsorge/"
                  className="block mt-4 text-base leading-6 text-gray-700 hover:text-gray-900"
                >
                  Altersvorsorge
                </Link>
              </nav>
            </div>
            <div>
              <h4 className="text-sm font-semibold leading-5 tracking-wider text-gray-500 uppercase">
                Finanzierungen
              </h4>
              <nav className="mt-4">
                <Link
                  to="/finanzierungen/baufinanzierung/"
                  className="block text-base leading-6 text-gray-700 hover:text-gray-900"
                >
                  Baufinanzierung
                </Link>
                <Link
                  to="/finanzierungen/privatkredit/"
                  className="block mt-4 text-base leading-6 text-gray-700 hover:text-gray-900"
                >
                  Privatkredit
                </Link>
              </nav>
            </div>
            <div>
              <h4 className="text-sm font-semibold leading-5 tracking-wider text-gray-500 uppercase">
                Geldanlage
              </h4>
              <nav className="mt-4">
                <Link
                  to="/geldanlage/allgemeine-geldanlage/"
                  className="block text-base leading-6 text-gray-700 hover:text-gray-900"
                >
                  Allgemeine Geldanlage
                </Link>
              </nav>
            </div>
            <div>
              <h4 className="text-sm font-semibold leading-5 tracking-wider text-gray-500 uppercase">
                Infos
              </h4>
              <nav className="mt-4">
                <Link
                  to="/ueber-uns/"
                  className="block text-base leading-6 text-gray-700 hover:text-gray-900"
                >
                  Über uns
                </Link>
                <Link
                  to="/info/faq/"
                  className="block mt-4 text-base leading-6 text-gray-700 hover:text-gray-900"
                >
                  FAQ
                </Link>
              </nav>
            </div>
            <div>
              <h4 className="text-sm font-semibold leading-5 tracking-wider text-gray-500 uppercase">
                Adresse
              </h4>
              <p className="mt-4 text-base leading-6 text-gray-700">
                {
                  data.allGooglePlaces.nodes[0].result.address_components[1]
                    .long_name
                }{" "}
                {
                  data.allGooglePlaces.nodes[0].result.address_components[0]
                    .long_name
                }
                ,<br />
                {
                  data.allGooglePlaces.nodes[0].result.address_components[7]
                    .long_name
                }{" "}
                {
                  data.allGooglePlaces.nodes[0].result.address_components[3]
                    .long_name
                }
              </p>
            </div>
          </div>
          <div className="mt-8 text-base leading-6 text-gray-700 md:order-1">
            <p className="mb-2">
              &copy; {year} Florian Dierda. Alle Rechte vorbehalten.
            </p>
            <nav className="flex flex-wrap">
              <Link to="/impressum/" className="inline mr-5 hover:underline">
                Impressum
              </Link>
              <Link to="/datenschutz/" className="inline mr-5 hover:underline">
                Datenschutz
              </Link>

              {typeof window !== `undefined` &&
                process.env.NODE_ENV !== "development" && (
                  <button
                    className="inline mr-5 hover:underline"
                    onClick={() => {
                      window.klaro.show()
                    }}
                  >
                    Cookie Einstellungen
                  </button>
                )}
            </nav>
          </div>
          <div className="mt-8 text-gray-500 text-small md:order-1">
            <p>
              Erstellt mit{" "}
              <span role="img" aria-label="Herz Emoticon">
                ❤️
              </span>{" "}
              zum Detail in Kassel.
            </p>
          </div>
        </div>
      </footer>
    </React.Fragment>
  )
}

export default FooterMain
