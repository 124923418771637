import React from "react"
import PropTypes from "prop-types"

const Input = ({
  id,
  label,
  required,
  name,
  placeholder,
  type,
  min,
  max,
  pattern,
  autoComplete,
  inputMode,
}) => {
  return (
    <React.Fragment>
      <label
        className="block mb-2 text-sm font-medium text-gray-900"
        htmlFor={id}
      >
        {label}
        {required && "*"}
      </label>
      <input
        className="w-full p-4 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
        id={id}
        name={name}
        placeholder={placeholder}
        type={type}
        required={required}
        min={min}
        max={max}
        pattern={pattern}
        autoComplete={autoComplete}
        inputMode={inputMode}
      />
    </React.Fragment>
  )
}

Input.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  autoComplete: PropTypes.string,
  inputMode: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  pattern: PropTypes.string,
}

export default Input
