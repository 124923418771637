import React from "react"
import { Transition } from '@headlessui/react'
import { Link } from "gatsby"
import Logo from "./../../../../../data/img/logo.svg"

const MobileMenu = props => {
  const mainLinks = props.mainLinks.map((link, i) => {
    return (
      <Link
        key={i}
        to={link.link}
        className="flex items-center p-3 -m-3 space-x-3 transition duration-150 ease-in-out rounded-md hover:bg-gray-100"
      >
        {link.linkSvg}
        <div className="text-base font-medium leading-6 text-gray-900">
          {link.linkName}
        </div>
      </Link>
    )
  })

  const svg = {
    faq: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="text-gray-700 "
        style={{ minWidth: 24 }}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <path d="M3 12h1M12 3v1M20 12h1M5.6 5.6l.7 .7M18.4 5.6l-.7 .7" />
        <path d="M9 16a5 5 0 1 1 6 0a3.5 3.5 0 0 0 -1 3a2 2 0 0 1 -4 0a3.5 3.5 0 0 0 -1 -3" />
        <line x1="9.7" y1="17" x2="14.3" y2="17" />
      </svg>
    ),
    blog: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="text-gray-700 "
        style={{ minWidth: 24 }}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M6 4h11a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-11a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1m3 0v18" />
        <line x1="13" y1="8" x2="15" y2="8" />
        <line x1="13" y1="12" x2="15" y2="12" />
      </svg>
    ),
    podcast: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="text-gray-700 "
        style={{ minWidth: 24 }}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <rect x="9" y="2" width="6" height="11" rx="3" />
        <path d="M5 10a7 7 0 0 0 14 0" />
        <line x1="8" y1="21" x2="16" y2="21" />
        <line x1="12" y1="17" x2="12" y2="21" />
      </svg>
    ),
  }

  return (
    <Transition
      show={props.state}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
      unmount={false}
    >
      <div className="absolute inset-x-0 top-0 z-20 p-2 transition origin-top-right transform lg:hidden">
        <div className="rounded-lg shadow-lg">
          <div className="bg-white divide-y-2 rounded-lg shadow-xs divide-gray-50">
            <div className="px-5 pt-5 pb-6 space-y-6">
              <div className="flex items-center justify-between">
                <div>
                  <Link to="/">
                    <img
                      className="w-auto h-7"
                      src={Logo}
                      alt="Florian Dierda Logo"
                    />
                  </Link>
                </div>
                <div className="-mr-2">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center p-2 text-gray-700 transition duration-150 ease-in-out rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500"
                    onClick={props.toggleMenu}
                  >
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div>
                <nav className="grid gap-8">{mainLinks}</nav>
              </div>
            </div>
            <div className="px-5 py-6 space-y-6">
              <div className="grid grid-cols-2 text-center sm:grid-cols-3 gap-y-4 gap-x-8">
                {/* <Link
                  to="/info/blog/"
                  className="flex items-center p-3 -m-3 space-x-3 transition duration-150 ease-in-out rounded-md hover:bg-gray-100"
                >
                  <span className="inline pr-2">{svg.blog}</span> Blog
                </Link> */}
                <Link
                  to="/info/faq/"
                  className="flex items-center p-3 -m-3 space-x-3 transition duration-150 ease-in-out rounded-md hover:bg-gray-100"
                >
                  <span className="inline pr-2">{svg.faq}</span> FAQ
                </Link>
              </div>
              <div className="space-y-6">
                <span className="flex w-full rounded-md shadow-sm">
                  <a
                    href={props.ctaLinkEnquiry}
                    className="flex items-center justify-center w-full px-4 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                  >
                    {props.ctaLinkEnquiryName}
                  </a>
                </span>
                <p className="text-base font-medium leading-6 text-center text-gray-500">
                  Fragen?{" "}
                  <Link
                    to="/kontakt/"
                    className="text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500"
                  >
                    Kontakt
                  </Link>{" "}
                  {/* oder{" "}
                  <Link
                    to="/info/"
                    className="text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500"
                  >
                    Infocenter
                  </Link> */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  )
}

export default MobileMenu
